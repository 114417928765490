<template>
  <div class="about d-flex flex-column text-dark position-relative py-5">
    <!--    <div class="grey-back position-absolute"></div>-->
    <div class="ksu-picture row px-3">
      <div class="picture-right">
        <img v-if="windowWidth <= breakPoint" src="../assets/ksu-back-new.jpeg" class="img-fluid">
      </div>
      <div :style="{hyphens: 'auto'}" :lang="$i18n.locale"
           :class="{ 'about-text': true, 'styleEn': this.$root.$i18n.locale === 'en','styleRu': this.$root.$i18n.locale === 'ru','styleDe': this.$root.$i18n.locale === 'de' }">
        <div class="" v-if="$i18n.locale === 'ru'">
          Ксения Кавко - зву&shy;ко&shy;ре&shy;жис&shy;сер, спе&shy;циа&shy;ли&shy;зи&shy;рующийся на клас&shy;сической музыке и джазе. Начав рабо&shy;тать в Минске
          звуко&shy;режис&shy;сером Бе&shy;ла&shy;рус&shy;кого государ&shy;ствен&shy;ного музыкаль&shy;ного театра и де&shy;лать проек&shy;ты с раз&shy;личны&shy;ми ан&shy;самбля&shy;ми,
          на&shy;при&shy;мер, <span class="cursive">Capella Sonorus</span> и <span class="cursive">Orchester Olympia Classic</span>, она про&shy;дол&shy;жила свое профес&shy;сиональ&shy;ное образо&shy;ва&shy;ние и
          карье&shy;ру за рубе&shy;жом. По&shy;сле полу&shy;че&shy;ния дипло&shy;ма с отли&shy;чием кафедры звуко&shy;режис&shy;суры БГАИ Ксения успеш&shy;но закон&shy;чила
          ма&shy;гис&shy;тра&shy;ту&shy;ры двух пре&shy;стиж&shy;ных про&shy;грамм в сфе&shy;ре аудио: <span class="cursive">Sound Recording, McGill University</span> <span :style="{whiteSpace: 'nowrap'}">(Монреаль, Канада)</span>
          и <span class="cursive">Musikregie / Tonmeister, Hochschule für Musik Detmold</span> (Германия). С 2021 она яв&shy;ляет&shy;ся час&shy;тью ко&shy;ман&shy;ды
          <a class="text-secondary" href="https://www.msm-studios.com/">msm-studios</a>, Мюнхен в кач&shy;ес&shy;тве му&shy;зыкаль&shy;ного про&shy;дю&shy;сера и звуко&shy;режис&shy;сера. В 2022 Ксения за&shy;ня&shy;ла по&shy;зи&shy;цию ве&shy;ду&shy;щего
          звуко&shy;режис&shy;сера в джаз-клубе <a class="text-secondary" href="https://www.unterfahrt.de/">Unterfahrt</a>, где она ре&shy;гу&shy;ляр&shy;но ра&shy;бо&shy;тает с вы&shy;даю&shy;щими&shy;ся не&shy;зави&shy;симы&shy;ми ар&shy;тис&shy;тами, а
          так&shy;же с музыкан&shy;та&shy;ми, подпи&shy;сан&shy;ными на такие лейблы как <span class="cursive">Blue Note Records</span>, <span class="cursive">ECM</span> и <span class="cursive">ACT</span>. Она про&shy;филирует&shy;ся не
          только на за&shy;писи альбо&shy;мов и концер&shy;тов, но и на рабо&shy;те со зву&shy;ком для жи&shy;вых тран&shy;сля&shy;ций, от соль&shy;ного фор&shy;те&shy;пиано
          до сим&shy;фони&shy;чес&shy;кого оркес&shy;тра и биг-бэнда. Ксения пре&shy;длагает со&shy;трудни&shy;чество для музыкант&shy;ов на трех разных языках:
          анг&shy;лий&shy;ском, не&shy;мец&shy;ком и рус&shy;ском, чтобы вмес&shy;те доне&shy;сти до слуша&shy;теля луч&shy;шую вер&shy;сию исполне&shy;ния и естес&shy;твен&shy;ную
          кра&shy;соту зву&shy;ча&shy;ния, как в стерео, так и в им&shy;мерсив&shy;ных фор&shy;ма&shy;тах.
        </div>
        <div class="" v-if="$i18n.locale === 'en'">
          Kseniya is a Tonmeister, specializing in classical music and jazz. After having gained experience as a sound
          engineer in Minsk at the <span class="cursive">Belarusian State Musical Theatre</span> and as a freelance
          Tonmeister with ensembles like
          the <span class="cursive">Capella Sonorus</span> and <span class="cursive">Orchester Olympia Klassika,</span>
          she continued her professional education and career
          abroad. Kseniya holds Master of Music degrees from two world-renowned audio programs: <span class="cursive">Sound Recording, McGill University <span :style="{hyphens: 'none', whiteSpace: 'nowrap'}">(Montréal, Canada)</span></span>
          and <span class="cursive">Musikregie / Tonmeister, Hochschule für Musik Detmold (Germany).</span> Since 2021
          she has been part of the team at <a class="text-secondary" href="https://www.msm-studios.com/">msm-studios</a> in Munich as a freelance
          music producer
          and recording engineer. In 2022 Kseniya took over the position of the lead sound engineer at the <a class="text-secondary" href="https://www.unterfahrt.de/">Jazz Club Unterfahrt</a>, where she regularly works with outstanding
          independent artists as
          well as with musicians signed by labels like <span class="cursive">Blue Note Records</span>, <span
            class="cursive">ECM</span> and <span class="cursive">ACT</span>. She has significant experience
          working in live conditions, not only recording concerts but also mixing for live streams, ranging from solo
          piano to symphony orchestras and jazz bands.
        </div>
        <div class="" v-if="$i18n.locale === 'de'" v-html="$t('about-2')">{{ $t('about-2') }}</div>
        <div v-if="$i18n.locale === 'en'">{{ $t('about-13') }}</div>
        <div v-if="$i18n.locale === 'de'" v-html="$t('about-13')"></div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  components: {},
  data() {
    return {
      settings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false
      },
      imageLoading: true,
      percentage: 0,
      breakPoint: 1230,
      windowWidth: window.innerWidth,
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    let el = this.$root.$el.querySelector('.about')
    el.style.maxHeight = 'none'
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    }
  }
}
</script>
<style lang="scss">
.about {
  padding-left: 7rem;
  padding-right: 7rem;
  @media screen and (max-width: 992px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  //height: calc(100vh - 210px);
  //@media screen and (max-height: 620px) and (min-width: 768px) {
  //  max-height: none;
  //}
  .grey-back {
    background: #F8F8F8;
    z-index: 1;
    top: 0;
    left: 0;
    height: calc(100vh - 210px);
    width: 100%;
  }

  .ksu-picture {
    display: grid;
    grid-template-columns: 64% 36%;
    grid-gap: 0;
    z-index: 2;
    //align-items: flex-end;
    padding: 0 7rem;
    @media screen and (max-width: 1280px) {
      padding: 0 5rem;
    }
    @media screen and (max-width: 992px) {
      padding: 1rem;
    }

    .styleEn {
      line-height: 140%;
    }

    .styleRu {
      line-height: 130%;
    }

    .styleDe {
      line-height: 140%;
    }

    .about-text {
      padding: 1.5rem;
      text-align: justify;
      word-break: break-word;
      font-size: 16px;
      z-index: 1;
      margin-right: auto;
      background-color: #fff;
      color: #333333;

      .cursive {
        font-family: raleway-medium-bold-cursive;
        cursor: text;
      }
    }

    .about-title {
      font-size: 20px;
      color: #332631;
    }

    .picture-right {
      background-image: url("../assets/ksu-back-new.jpeg");
      background-position: center center;
      background-size: cover;
      //max-height: calc(100vh - 151px);
    }
  }

  @media screen and (max-width: 1230px) {
    .ksu-picture {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
}

@media (max-width: 900px) {
  .about {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.image-hidden {
  display: none;
}

.spinner.spinner--plane {
  margin: auto;
}

#top .plane {
  background: rgba(99, 50, 62, 1);
}

#middle .plane {
  background: rgb(49, 25, 31) !important;
}

#bottom .plane {
  background: rgba(99, 50, 62, 1);
}

/* @media screen and (min-height: 800px) {
    .about-text {
        font-size: 17px;
    }
}
@media screen and (min-height: 900px) {
    .about-text {
        font-size: 18px;
    }
}
@media screen and (min-height: 950px) {
    .about-text {
        font-size: 19px;
    }
}
@media screen and (min-height: 1050px) {
    .about-text {
        font-size: 20px;
    }
} */
// @media screen and (max-width: 992px) {
//     .about-text {
//         width: 100%;
//         margin-left: 0;
//     }
//     .ksu-picture {
//         width: 100%;
//         background-size: contain;
//         background-position: top;
//         position: unset;
//     }
// }
// @media screen and (max-width: 768px) {
//     .ksu-picture {
//         background-size: contain;
//         background-position: top;
//     }
//     .about-text {
//         height: 600px;
//     }
// }
/* @media screen and (max-width: 528px) {
    .ksu-picture {
        height: 266px;
    }
}
@media screen and (max-width: 400px) {
    .ksu-picture {
        height: 212px;
    }
} */
</style>
